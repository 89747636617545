<template>
	<div class="container">
		<div class="handle-box" style="margin: 5px 0;">
			<el-button @click="addVisible=true">新增</el-button>
		</div>
		<el-table :data="tableData" border class="table" ref="multipleTable" header-cell-class-name="table-header"
			v-loading="tableLoading">
			<el-table-column prop="title" label="原因" align="center"></el-table-column>
			<el-table-column prop="created_at" label="创建时间" align="center"></el-table-column>
			<el-table-column label="操作" width="180" align="center">
				<template #default="scope">
					<el-button type="text" style="color: red;" icon="el-icon-delete" @click="handle(scope.row)">删除
					</el-button>
				</template>
			</el-table-column>
		</el-table>
		<div class="pagination">
			<el-pagination background layout="total, prev, pager, next" :current-page="query.page"
				:page-size="query.limit" :total="pageTotal" @current-change="handlePageChange"></el-pagination>
		</div>
		<el-dialog title="新增审核原因" v-model="addVisible" width="30%">
			<el-form ref="form" :model="addQuery" label-width="80px">
				<el-form-item label="审核原因">
					<el-input v-model="addQuery.title"></el-input>
				</el-form-item>
			</el-form>
			<template #footer>
				<span class="dialog-footer">
					<el-button @click="addVisible = false">取 消</el-button>
					<el-button type="primary" @click="save">确 定</el-button>
				</span>
			</template>
		</el-dialog>
	</div>
</template>

<script>
	import {
		watch,
		reactive,
		ref
	} from 'vue'
	import {
		ElMessageBox,
		ElMessage
	} from 'element-plus'
	import request from '@/utils/request.js'
	export default {
		props: {
			type: String
		},
		setup(props) {
			let tableLoading = ref(false),
				pageTotal = ref(0),
				tableData = ref([]),
				query = reactive({
					page: 1,
					type: props.type,
					limit: 15
				}),
				addQuery = reactive({
					type: props.type,
					title: ''
				}),
				addVisible = ref(false)
			const getList = () => {
				tableLoading.value = true
				request({
					url: 'admin/reason/getList',
					method: 'get',
					params: {
						...query
					}
				}).then((res) => {
					tableLoading.value = false;
					if (res.code == 200) {
						tableData.value = res.data.data;
						pageTotal.value = res.data.total;
					}
				}).catch(() => {
					tableLoading.value = false;
				});
			}
			const handlePageChange = (val) => {
				query.page = val
				getList()
			}
			const handle = (item) => {
				ElMessageBox.confirm('此操作将永久删除该项, 是否继续?', '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					request({
						url: 'admin/reason/del',
						method: 'DELETE',
						data: {
							id: item.id
						},
					}).then((res) => {
						if (res.code == 200) {
							ElMessage.success("删除成功")
							getList()
						} else {
							ElMessage.error(res.msg)
						}
					})
				}).catch(() => {
				
				});
			}
			const save = () => {
				request({
					url: 'admin/reason/add',
					method: 'post',
					data: {
						...addQuery
					}
				}).then((res) => {
					if (res.code == 200) {
						getList()
						ElMessage.success(res.msg)
						addVisible.value = false
					} else {
						ElMessage.error(res.msg)
					}
				}).catch(() => {});
			}
			watch(
				()=> addVisible.value,
				(val) => {
					if(!val) {
						addQuery.title= ''
					}
				}
			)
			getList()
			return {
				getList,
				tableData,
				pageTotal,
				handlePageChange,
				query,
				addQuery,
				addVisible,
				tableLoading,
				handle,
				save
			}
		}
	}
</script>

<style>
</style>
