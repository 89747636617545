<template>
    <div>
		<div class="crumbs">
			<el-breadcrumb separator="/">
				<el-breadcrumb-item>
					<i class="el-icon-lx-cascades"></i> 审核原因管理
				</el-breadcrumb-item>
			</el-breadcrumb>
		</div>
        <el-tabs v-model="type" type="card" @tab-click="tabClick">
            <el-tab-pane label="资源审核" name="1">
                <item :activeType="type" type="1"/>
            </el-tab-pane>
            <el-tab-pane label="用户信息审核" name="2">
                <item :activeType="type" type="2"/>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
import item from '@/components/basis/reasonItem.vue'
export default {
    name: 'review',
    components: {
        item
    },
    data() {
        return {
            type: '1', //
        };
    },
    created() {},
    methods: {
        tabClick(e) {
            //切换type
            this.type = e.props.name
        }
    }
}
</script>
<style scoped>

</style>